import { Component } from 'react';
import { restGet } from '../components/RESTfunctions';
import ServerStatus from './ServerStatus';
import ChartComponent from './ChartComponent';
import ControlComponent from './ControlComponent';
import { Flex, Heading } from '@aws-amplify/ui-react';

class TopComponent extends Component {
    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
            result: null,
            lineCharts: {}
        };

        this.reload = this.reload.bind(this);
        this.setChange = this.setChange.bind(this);
    }

    componentDidMount() {
        document.title = "US Rates & Curve by @beikabu_oyaji (米株親父)"
        this.reload();
    }

    async reload() {
        console.log("reload")
        const result = await restGet('instachartapi', '/ping/0.1');
        this.setState({
            result: result
        })
    }

    setChange(lineCharts) {
        console.log("setChange");
        console.log(lineCharts);
        //this.state.items = items;
        this.setState({ lineCharts: lineCharts })
    }

    render() {
        return (
            <>
                <Heading>Rates & Curves by @beikabu_oyaji (米株親父)</Heading>
                <Flex width="100%" height="100%">
                    <ChartComponent data={this.state.result?.list} lineCharts={this.state.lineCharts} />
                    <ControlComponent onChange={this.setChange} />
                </Flex>
                <ServerStatus serverStatus={this.state.result?.ping} onSubmit={this.reload} />
            </>
        )
    }
}

export default TopComponent;