import './App.css';
import TopComponent from './components/TopComponent';

function App() {
  return (
    <TopComponent />
  );
}

export default App;
