import { Component } from 'react';
import { CheckboxField, Flex } from '@aws-amplify/ui-react';
import './ControlComponent.css';

class ControlComponent extends Component {
    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
            "lineCharts": {
                "US 3M": {"show": true, "color": "#96284e", "css": "us-3m"},
                "US 2Y": {"show": true, "color": "#52e070", "css": "us-2y"},
                "US 5Y": {"show": true, "color": "#e21595", "css": "us-5y"},
                "US 10Y": {"show": true, "color": "#1ab134", "css": "us-10y"},
                "US 30Y": {"show": true, "color": "#004dbf", "css": "us-30y"},
                "3M-2Y": {"show": true, "color": "#c7cd4c", "css": "us3m-2y"},
                "3M-5Y": {"show": true, "color": "#82337a", "css": "us3m-5y"},
                "3M-10Y": {"show": true, "color": "#3b6900", "css": "us3m-10y"},
                "3M-30Y": {"show": true, "color": "#fdace9", "css": "us3m-30y"},
                "2Y-5Y": {"show": true, "color": "#c71e14", "css": "us2y-5y"},
                "2Y-10Y": {"show": true, "color": "#01caf3", "css": "us2y-10y"},
                "2Y-30Y": {"show": true, "color": "#ff5c54", "css": "us2y-30y"},
                "5Y-10Y": {"show": true, "color": "#00978b", "css": "us5y-10y"},
                "5Y-30Y": {"show": true, "color": "#9e4100", "css": "us5y-30y"},
                "10Y-30Y": {"show": true, "color": "#8f3525", "css": "us10y-30y"},
            }
        }
    }

    componentDidMount() {
        this.props.onChange(this.state.lineCharts)
    }

    controlStatus(itemName) {
        console.log(itemName, this.state.lineCharts[itemName]);
        this.setState((prevState) => {
            let lineCharts = Object.assign({}, prevState.lineCharts);
            console.log(lineCharts[itemName]["show"])
            lineCharts[itemName]["show"] = !lineCharts[itemName]["show"];
            console.log(lineCharts[itemName]["show"])
            return {lineCharts};
        }, () => this.props.onChange(this.state.lineCharts));
        
    }

    render() {
        return (
            <Flex width="100px" wrap="no-wrap" direction="column">
                {
                    Object.keys(this.state.lineCharts).map((k) => {
                        return (
                            <CheckboxField
                                key={k}
                                name={k}
                                label={k}
                                size="small"
                                className={this.state.lineCharts[k]["css"]}
                                checked={this.state.lineCharts[k]["show"]}
                                onClick={(e) => this.controlStatus(e.target.name)}
                            />
                        )
                    })
                }
            </Flex>
        )
    }
}

export default ControlComponent;