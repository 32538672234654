import { Component } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';


class ChartComponent extends Component {
    constructor(props) {
        super(props);
        console.log(props);
    }
    render() {
        console.log(Object.keys(this.props.lineCharts))
        if (this.props.data)
            return (
                <ResponsiveContainer width="100%"
                    height={600} >
                    <LineChart
                        data={this.props.data}
                        margin={{ top: 10, right: 20, left: 20, bottom: 50 }}
                    >
                        {
                            Object.keys(this.props.lineCharts).map((k) => {
                                if (this.props.lineCharts[k]["show"]) {
                                    return (
                                        <Line type="monotone" dataKey={k} dot={false}
                                            stroke={this.props.lineCharts[k]["color"]}
                                            key={Math.random()}
                                        />
                                    )
                                }
                            })
                        }
                        <XAxis
                            dataKey="timestamp"
                            tickFormatter={(ts) => moment.utc(ts).local().format("MM-DD HH:mm")}
                            domain={['auto', 'auto']}
                            angle={-45}
                            textAnchor="end"
                        />
                        <YAxis />
                        <CartesianGrid stroke="#ccc" />
                        <Tooltip />
                    </LineChart>
                </ResponsiveContainer>
            )
    }
}

export default ChartComponent;