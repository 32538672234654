import { Component } from 'react';
import { Badge, Flex } from '@aws-amplify/ui-react';

class ServerStatus extends Component {
    render() {
        var message = {
            badge: "",
            message: ""
        }
        if (this.props.serverStatus) {
            message.badge = "success";
            message.message = "Server Ready (Click to Reload)";
        } else {
            message.badge = "error";
            message.message = "Server Not Ready. Please wait.";
        }
        return (
            <Flex direction="row-reverse">
                <Badge onClick={this.props.onSubmit} variation={message.badge}>{message.message}</Badge>
            </Flex>
        )
    }
}

export default ServerStatus;